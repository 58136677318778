// 주 색상
$primary-color: #145a9f;

// 보조 색상
$secondary-color: #f0b536;

// 보조 색상 2
$tertiary-color: #df2d1f;

// 경고 색상
$warn-color: $tertiary-color;

// 버튼, 인풋 등 모서리
$border-radius: 10px;

// 모바일 헤더 높이
$header-height-mobile: 50px;

// 데스크톱 헤더 높이
$header-height-desktop: 80px;

// 모바일 푸터 높이
$footer-height-mobile: 216px;

// 데스크톱 푸터 높이
$footer-height-desktop: 418px;
