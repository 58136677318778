/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@use 'variable' as *;
@use 'external';

@import 'theme';
@import 'material';
@import 'normalize';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');

html {
  color: $font-color;
  font-family: 'Noto Sans JP', sans-serif;

  // @media (max-width: 991.98px) {
  //   font-size: 14px;
  // }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// 모바일 앱의 경우
.mobile-app {
  * {
    // 링크 롱 터치 불가
    -webkit-touch-callout: none;
  }
}

// 크롬 스크롤 복원용 래퍼
main[wrapper] {
  min-height: 100vh;
  background-color: #fff;
}

// ios safearea top, bottom, left, right
[safearea] {
  @extend [safe-top];
  @extend [safe-bottom];
  @extend [safe-side];
}

// ios safearea top
[safe-top] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-top: constant(safe-area-inset-top);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

// ios safearea bottom
[safe-bottom] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-bottom: constant(safe-area-inset-bottom);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

// ios safearea left, right
[safe-side] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

[spacer] {
  width: 100%;
  height: 8px;
  background-color: $space-color;
}

::-webkit-scrollbar {
  width: 0px;
}

input {
  background-color: transparent;
}

button {
  color: inherit;
}

.gakuyu-body {
  padding: 1rem 0;

  @media (min-width: 1200px) {
    padding: 3.5rem 0 4rem;
  }

  @media (min-width: 992px) {
    padding: 3rem 0 3.5rem;
  }

  @media (min-width: 768px) {
    padding: 2.5rem 0 2.5rem;
  }
}

.gakuyu-content {
  margin: auto;
  padding: 0 1rem;
  width: calc(100% - 2rem);

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

@mixin gakuyu-button {
  width: 100%;
  height: 46px;
  background-color: $theme-primary-500;
  color: #fff;
  font-size: 16px;
}

[gakuyu-button] {
  @include gakuyu-button();
}

[gakuyu-button='negative'] {
  @include gakuyu-button();
  border: 1px solid $theme-primary-500;
  background-color: #fff;
  color: $theme-primary-500;
}

[gakuyu-button='invalid'] {
  @include gakuyu-button();
  background-color: $space-color;
  color: $font-color-light;
}

[gakuyu-button='sub'] {
  @include gakuyu-button();
  background-color: $theme-secondary-500;
}

[gakuyu-list] {
  margin-top: 16px;
}

[gakuyu-list-item] {
  border-bottom: 1px solid rgba($color: $border-color, $alpha: 0.4);
  padding: 0 16px;
  height: 52px;
  display: flex;
  align-items: center;
}

[gakuyu-list-item-name] {
  font-size: 15px;
}

label[error] {
  margin-top: 4px;
  padding: 4px 16px;
  display: block;
  background: #f9f9f9;
  color: $theme-warn-500;
  font-weight: normal;
  font-size: 14px;
}

mat-dialog-container.mat-dialog-container {
  border-radius: 0;
  padding: 16px;
}

.fullscreen-panel {
  mat-dialog-container.mat-dialog-container {
    padding: 0;
  }
}

@mixin dialog-panel {
  min-width: 240px;
}

.dialog-panel {
  @include dialog-panel();
}

.fullscreen-panel {
  @include dialog-panel();
  min-width: 640px;
}

.mobile {
  .fullscreen-panel {
    @include dialog-panel();
    width: 100vw;
    height: 100vh;
  }
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 5px;
  height: 3rem;
  font-size: 1rem;
  font-weight: normal;
  transition: all 0.1s ease-in;
}

div.mat-select-panel {
  border-radius: 0;
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-option.mat-active {
  background: $theme-sub-color;
}

app-gakuyu-layout,
app-gakuyu-header {
  .mat-mini-fab .mat-button-wrapper {
    line-height: 23px;
  }

  .mat-expansion-panel-header {
    padding-left: 16px;
    height: 40px;
    font-size: 1rem;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 40px;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  @media (max-height: 560px) {
    .mat-expansion-panel-header,
    .mat-expansion-panel-header.mat-expanded {
      height: 32px;
    }
  }
}

app-gakuyu-fixed-button-container {
  .container {
    button {
      margin: 16px 16px 16px 0;
      flex: 1;
    }

    button:first-child {
      margin-left: 16px;
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  &.area-title {
    margin-bottom: 0;

    @media (max-width: 768px) {
      margin: 24px 0 40px;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
    }
  }
}

#ifrWrapper {
  width: 100%;
  height: 100%;

  #ifr {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.bus-select .mat-select-value {
  color: $font-color;
}
.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
